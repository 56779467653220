/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-add': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path d="M0.941176 8C0.941176 4.10152 4.10152 0.941176 8 0.941176C8.2599 0.941176 8.47059 0.730487 8.47059 0.470588C8.47059 0.21069 8.2599 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 7.7401 15.7893 7.52941 15.5294 7.52941C15.2695 7.52941 15.0588 7.7401 15.0588 8C15.0588 11.8985 11.8985 15.0588 8 15.0588C4.10152 15.0588 0.941176 11.8985 0.941176 8ZM4.96798 9.74966C4.82971 9.51063 4.52385 9.42895 4.28482 9.56722C4.04578 9.70549 3.9641 10.0114 4.10238 10.2504C4.87969 11.5941 6.33379 12.5 8.00005 12.5C9.66631 12.5 11.1204 11.5941 11.8977 10.2504C12.036 10.0114 11.9543 9.70549 11.7153 9.56722C11.4763 9.42895 11.1704 9.51063 11.0321 9.74966C10.4262 10.7971 9.29491 11.5 8.00005 11.5C6.70518 11.5 5.5739 10.7971 4.96798 9.74966ZM7 6.5C7 7.32843 6.55228 8 6 8C5.44772 8 5 7.32843 5 6.5C5 5.67157 5.44772 5 6 5C6.55228 5 7 5.67157 7 6.5ZM10 8C10.5523 8 11 7.32843 11 6.5C11 5.67157 10.5523 5 10 5C9.44771 5 9 5.67157 9 6.5C9 7.32843 9.44771 8 10 8ZM13.5 0C13.7761 0 14 0.223858 14 0.5V2L15.5 2C15.7761 2 16 2.22386 16 2.5C16 2.77614 15.7761 3 15.5 3L14 3V4.5C14 4.77614 13.7761 5 13.5 5C13.2239 5 13 4.77614 13 4.5V3L11.5 3C11.2239 3 11 2.77614 11 2.5C11 2.22386 11.2239 2 11.5 2L13 2V0.5C13 0.223858 13.2239 0 13.5 0Z" />',
    },
});
